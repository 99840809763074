<template>
  <div>
    <v-form>
      <!-- Data Table -->
      <h3 class="pt-6 pb-4">Calibration Data</h3>

      <p class="mb-2">a) Enter the concentration of the stock solution below:</p>

      <calculation-input
        v-model="inputs.sConc"
        prepend-text="$\text{[Stock]:}$"
        append-text="$\text{M}$"
        class="mb-5"
      />

      <p class="mb-2">
        b) Please fill in the data table below with the results and associated calculations for this
        experiment:
      </p>

      <v-simple-table class="mb-5">
        <thead>
          <tr>
            <td style="font-weight: bold">Sample</td>
            <td style="font-weight: bold; text-align: center">V<sub>Dye</sub> (mL)</td>
            <td style="font-weight: bold; text-align: center">V<sub>solvent</sub> (mL)</td>
            <td style="font-weight: bold; text-align: center">V<sub>total</sub> (mL)</td>
            <td style="font-weight: bold; text-align: center">Concentration (M)</td>
            <td style="font-weight: bold; text-align: center">Abs</td>
          </tr>
        </thead>
        <tr v-for="i in 5" :key="`known-${i}`">
          <td>{{ i }}</td>
          <td>
            <calculation-input v-model="inputs[`mLdye${i}`]" class="centered-input" />
          </td>
          <td>
            <calculation-input v-model="inputs[`mLsolv${i}`]" class="centered-input" />
          </td>
          <td style="text-align: center">
            {{ (Number(inputs['mLdye' + i]) + Number(inputs['mLsolv' + i])).toFixed(2) }}
          </td>
          <td>
            <calculation-input
              v-model="inputs[`conc${i}`]"
              class="centered-input"
              :show-scientific-notation="false"
            />
          </td>
          <td>
            <calculation-input v-model="inputs[`abs${i}`]" class="centered-input" />
          </td>
        </tr>
      </v-simple-table>

      <p class="mb-2">
        c) Construct a calibration curve and perform a linear regression using the appropriate data
        in the table above. The data should be chosen such that the value of
        <stemble-latex content="$\varepsilon$" /> can be obtained from the linear regression.
      </p>

      <calculation-input v-model="inputs.epsilon" prepend-text="$\varepsilon:$" />
      <!-- Data Upload -->
      <h3 class="pt-8 pb-3">Graph Upload</h3>

      <p class="mb-2">d) Upload your calibration plot below:</p>

      <p class="mb-5">
        <file-upload-ui-component
          style="width: 100%"
          label="Click here to select a file"
          :max-number-of-files="1"
          :max-file-size="20"
          accepted-file-extensions=".png, .jpg ,.jpeg, .pdf"
          @upload:attachments="attachments = $event"
        />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default {
  name: 'BeerPostLabQ2',
  components: {FileUploadUiComponent, StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        sConc: null,

        mLdye1: '0',
        mLdye2: '0',
        mLdye3: '0',
        mLdye4: '0',
        mLdye5: '0',

        mLsolv1: '0',
        mLsolv2: '0',
        mLsolv3: '0',
        mLsolv4: '0',
        mLsolv5: '0',

        conc1: null,
        conc2: null,
        conc3: null,
        conc4: null,
        conc5: null,

        abs1: null,
        abs2: null,
        abs3: null,
        abs4: null,
        abs5: null,

        epsilon: null,
      },
      attachments: [],
    };
  },
};
</script>
